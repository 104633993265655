import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import NestedContentRow from "../components/nestedContentRow";
import NumberLine from "../components/NumberLine";
import FAQ from "../components/FAQ";
import smoothScrollTo from "../components/smoothScroll";

import ALTechVideo from "../images/ALAttendanceKiosk.mp4";
import ALTechPoster from "../images/ALPosterAttendance.jpg";

const AttendancePage = () => {
    const imgData = useStaticQuery(graphql`
        query {
            fullClientDesktop: file(relativePath: { eq: "AllDevicesAttendanceBubble.png" }) {
                childImageSharp {
                    fluid(maxWidth: 550) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            fullClientMobile: file(relativePath: { eq: "AllDevicesAttendance.png" }) {
                childImageSharp {
                    fluid(maxWidth: 376) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            arrowDownWhiteIcon: file(relativePath: { eq: "ArrowDownWhite.png" }) {
                childImageSharp {
                    fixed(width: 15) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            kiosk1: file(relativePath: { eq: "kioskCheckIn1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kiosk2: file(relativePath: { eq: "kioskCheckIn2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            fullclientHome: file(relativePath: { eq: "FullClientHome.png" }) {
                childImageSharp {
                    fluid(maxWidth: 450) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const mediaQuerySources = [
        {
            ...imgData.fullClientMobile.childImageSharp.fluid,
            media: `(max-width: 1366px)`
        },
        {
            ...imgData.fullClientDesktop.childImageSharp.fluid,
            media: `(min-width: 1367px)`,
        }
    ];
    const FAQData = [
        {
            question: "Can the kiosk run disconnected?",
            answer: ["The ALMobile Attendance Kiosk requires an internet connection. The Kiosk works best indoors, such as fabrication facilities or warehouses."]
        },
        {
            question: "How do the biometrics work on the kiosk?",
            answer: ["The ALMobile Attendance Kiosk is an enterprise solution that is easy and simple to use. The facial and fingerprint biometric technology uses measurement points to authenticate and identify individual employees as they check in check out of the system. The Attendance Kiosk is configurable to track attendance only or provides the employee with the ability to verify a job and/or task while checking in. Upon checking out, the system creates a time record along with the attendance. Both facial and fingerprint methods are designed to recognize an employee through technology without direct human supervision."]
        },
        {
            question : "What devices does your Attendance tracking work on?",
            answer: ["The ALMobile Attendance Kiosk leverages Microsoft Windows systems. Common supported deployments are Personal Computers or Microsoft Surface devices."]
        },
        {
            question : "Is your software multi-lingual?",
            answer: [
                "Yes, we offer our software licenses in the following languages: English, French, and Spanish"
            ]
        },
        {
            question : "What ERP do you integrate to?",
            answer: ["We integrate to most major ERP systems. Some of those systems include: AMSI, CGC, CMiC, COINS, Dexter & Chaney, Explorer, JD Edwards, Microsoft Dynamics, Oracle, Penta, SAGE, SAP, Viewpoint, and more. Don’t see your ERP/accounting system in this list? Not a problem! We are constantly growing the list of partners and vendors that we work with in order to provide the best integration experience for our clients."]
        },
        {
            question : "What industries do you serve?",
            answer: ["We serve a wide variety of industries working in any environment. Some of those industries include: Construction, Power, Environmental, Heavy Highway, Industrial, Mining, Oil & Gas, and Rail. The Kiosk works best indoors, such as fabrication facilities or warehouses."]
        },
    ];
    const numberRows = {
        general: [
            {
                data:
                    <NestedContentRow
                        customProps={{ "data-id": 1 }}
                        iconData={{
                            iconType: "fluid",
                            icon: imgData.kiosk1.childImageSharp.fluid,
                            iconAlt: "Face Recognition"
                        }}
                        textData={{
                            level1: "Biometric Capabilities",
                            level2: "ALMobile’s Attendance Kiosk is designed to quickly capture attendance and job costing with biometric face recognition or fingerprint authentication. This eliminates buddy punching and ghost employees altogether, and gives you the ability to instantly verify attendance at the beginning of work shifts"
                        }}
                        link={<div className={"level3"}>
                            <Link className={"link btn"} to={`/events/`} state={{component: "webinars"}}>Learn About Our Intergration</Link>
                        </div>}
                    />
            },
            {
                data:
                    <NestedContentRow
                        customProps={{ "data-id": 2 }}
                        iconData={{
                            icon: imgData.kiosk2.childImageSharp.fluid,
                            iconAlt: "kiosk check in",
                            iconType: "fluid"
                        }}
                        textData={{
                            level1: "Generate Time from Attendance",
                            level2: "This feature automatically creates time records for the entire jobsite once attendance data is approved, saving you time. The data is automatically scrubbed with intelligent technology to direct you to incorrect attendance information as well"
                        }}
                        link={<div className={"level3"}>
                            <Link className={"link btn"} to={`/events/`} state={{component: "webinars"}}>See Our Reporting Capabilites</Link>
                        </div>}
                    />
            },
            {
                data:
                    <NestedContentRow
                        customProps={{ "data-id": 3 }}
                        iconData={{
                            iconType: "fluid",
                            icon: imgData.fullclientHome.childImageSharp.fluid,
                            iconAlt: "ALMobile Full Client",
                        }}
                        textData={{
                            level1: "Attendance with Job Costing Entry",
                            level2: "A crew checks in and out with the Attendance Kiosk and selects the project or cost code that they are working on. Checking in and out can occur several times within the shift, allowing employees to change their costing as needed"
                        }}
                        link={<div className={"level3"}>
                            <Link className={"link btn"} to={`/events/`} state={{component: "webinars"}}>Register for a Webinar to See How</Link>
                        </div>}
                    />
            },
            {
                data:
                    <NestedContentRow
                        customProps={{ "data-id": 4 }}
                        textData={{
                            level1: "Control Labor Cost",
                            level2: "Never worry about ghost employees or buddy-punching again. ALMobile Attendance Tracking provides your company with powerful job costing capabilities all in the palm of your hand."
                        }}
                    />
            }
        ],
        video:
            <video
                controls
                poster={ALTechPoster}
            >
                <source src={ALTechVideo}/>
            </video>
    };
    const startScroll = _ => {
        const row1 = document.querySelector(".numberRow[data-id='1']");
        smoothScrollTo(row1.offsetTop, 300);
    };
    return (
        <Layout pageID={"ALMobileAttendanceTracking"}>
            <SEO
                title="ALMobile Attendance Tracking"
                description={"Our Biometric Attendance Solution is designed to quickly capture attendance and job costing with biometric face recognition and fingerprint authentication. Check in and check out with the Attendance Kiosk at the home office or take it to your manufacturing and fabrication facilities."}
            />
            <div className={"contentRow productOverview"}>
                <div className={"subContentRow"}>
                    <div className={"textContainer"}>
                        <div className={"ProductOverviewMainSubHeader"}>PRODUCT OVERVIEW</div>
                        <div className={"level1 text"}>
                            ALMobile Attendance Tracking
                        </div>
                        <div className={"level2 text"}>
                            ALMobile's Attendance Tracking provides your organization with the
                            ability to quickly and easily collect attendance information for crews and individuals.
                            Utilize advanced facial recognition technology or biometric fingerprinting capabilities.
                            Say goodbye to ghost employees and buddy-punching and say hello to capturing attendance and job costing with a simple finger print.
                        </div>
                    </div>
                    <div className={"startOverviewContainer"}>
                        <div
                            className={"startOverviewBtnContainer"}
                            onClick={startScroll}
                            onKeyUp={startScroll}
                        >
                            <Img
                                className={"startOverviewBtn"}
                                fixed={imgData.arrowDownWhiteIcon.childImageSharp.fixed}
                                alt={"Start Btn"}
                                style={{ display: "block" }}
                            />
                        </div>
                        <div
                            className={"startOverviewLabel"}
                            onClick={startScroll}
                            onKeyUp={startScroll}
                        >
                            LEARN MORE
                        </div>
                    </div>
                    <Img
                        className={"imgContainer"}
                        style={{ display: "block" }}
                        fluid={mediaQuerySources}
                        imgAlt={"ALMobile Desktop"}
                    />
                </div>
            </div>
            <NumberLine rows={numberRows} />
            <FAQ QAData={FAQData} />
        </Layout>
    )
};

export default AttendancePage;
